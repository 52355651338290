.myarea-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: flex-start;
  padding: 1rem;
}

.myarea-container .two-thirds {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(66.66667% - 1.5rem);
  max-width: calc(66.66667% - 1.5rem);
}
.myarea-container .one-third {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(33.333% - 1.5rem);
  max-width: calc(33.333% - 1.5rem);
}
@media (max-width: 992px) {
  .myarea-container .two-thirds {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%;
    max-width: 100%;
  }
  .myarea-container .one-third {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%;
    max-width: 100%;
  }
}
.myarea-container h3 {
  color: var(--headline-color);
  flex: 0 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .myarea-container {
    gap: 1rem;
  }
  .myarea-container h3 {
    flex-direction: column;
    align-items: flex-start;
  }
  .myarea-container h3 .edit-profile {
    margin-top: 0.5rem;
  }
}

/* ✅ Styling for Sections */
.profile-section h3,
.appointments-section h3 {
  color: var(--tertiary-color);
  margin-bottom: 1rem;
}

/* ✅ Input Fields */
input {
  padding: 0.8rem;
  margin-top: 0.5rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--button-border-radius);
  background: var(--background-color);
  color: var(--body-color);
}

.btn-save {
  background: var(--success-color);
  color: var(--text-dark);
}

.btn-edit {
  background: var(--primary-color);
  color: var(--text-dark);
}

.btn-cancel {
  background: var(--active-color);
  color: var(--text-dark);
}
.appointment-items {
  margin-top: 2rem;
}

.appointment-item {
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: var(--button-border-radius);
  color: var(--tertiary-color);
  border: 2px solid var(--active-color);
}

.edit-profile {
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--active-color);
}

.appointments-section .list-item {
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.appointments-section .list-item .list-item-category {
  margin-left: 0;
}

@media (min-width: 992px) {
  .appointments-section .list-item .edit-profile {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.user-card {
  margin-top: 2rem;
  border: 2px solid var(--secondary-color);
  border-radius: var(--button-border-radius);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-card p {
  margin: 0.5rem 0;
}

.user-card .edit-profile {
  margin-top: 1rem;
}

.appointments-section .list-item .service-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.appointments-section .list-item .edit-profile {
  color: var(--tertiary-color);
}

.user-card input {
  width: calc(100% - 2rem);
  padding: 0.75rem !important;
  margin: 10px 0;
  border-radius: 8px;
  transition: border 0.3s ease-in-out;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: var(--secondary-color) !important;
  border: 2px solid transparent !important;
}

.userform-container input:focus {
  border-color: var(--active-color) !important;
  outline: none;
}

.user-card .user-card-actions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: .5rem;
  margin-top: 10px;
}
.user-card p {
  font-size: 0.75rem;
}
.user-card .user-name {
  font-size: 1rem;
  font-weight: bold;
}

.appointment-datetime {
  font-size: 0.75rem;
}

.appointment-datetime span {
  font-size: .75rem;
  display: block;
}


ul.list-items.appointment-items span {
  font-weight: bold;
}

.user-card .user-mail, .user-card .user-phone {
  margin: 0;
}

.user-card-actions button.edit-profile {
  flex: 0 1 100%;
  text-align: right;
}

.user-card-actions button.cancel, .user-card-actions button.save {
  flex: 0 1 calc(50% - .25rem);
}
