.accordion-container {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    border-radius: var(--button-border-radius);

}

.accordion-items-container {
    flex: 0 1 100% !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.accordion-item {
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    border-radius: var(--button-border-radius);
    padding: 1.5rem;

}
.title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordion-item svg{
    color: var(--primary-color);
    font-size: 1.25rem;
} 
.accordion-item p {
    color: var(--body-color);
    text-align: left !important;
}
.accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
}
.accordion-title h6 {
    margin: 0;
}
.accordion-content-element {
    margin: 1rem 0;
}
.accordion-content-container {
    transition: all 0.25s ease-in-out;
    overflow: hidden;
}


.accordion-item a {
    padding: 0;
}