.bookings-container {
  margin: 1rem;
  width: 100%;
  height: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bookings-container .list-items li.selected {
  border: 2px solid var(--active-color);
}

.bookings-container .list-wrapper,
.bookings-container .date-picker-wrapper,
.bookings-container .timeslots-wrapper,
.bookings-container .user-details-form,
.bookings-container .overview-wrapper,
.waitlist-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.bookings-container button {
  text-transform: uppercase;
}

.timeslots-wrapper,
.waitlist-wrapper {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.waitlist-wrapper {
  gap: 1rem;
}

.waitlist-wrapper label {
  font-size: 1rem;
  color: var(--body-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.waitlist-wrapper button {
  margin-top: 1rem;
}

.timeslots-wrapper ul li {
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.timeslots-wrapper > button {
  margin-top: 1rem;
}

.date-picker-wrapper {
  margin: 1rem;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.overview-wrapper {
  margin: 1rem;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.overview-wrapper p {
  margin: 0;
}

#booking-date {
  padding: 1rem;
  background-color: transparent;
  border: 2px solid var(--secondary-color);
  color: var(--tertiary-color);
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="time"] {
  padding: 1rem;
}

.list-items li {
  font-weight: normal;
}

li.list-item.non-bookable.selected {
  border: 2px solid var(--secondary-color);
  animation: horizontal-shaking 0.5s 1;
  animation-delay: 0.1s;
}

li.list-item.non-bookable {
  filter: grayscale(1);
}

.user-details-form {
  margin: 1rem;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bookings-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: calc(100% / 6); /* Assuming 6 steps */
  position: relative;
}

.step-indicator .step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 5px;
  z-index: 1;
}

.step-indicator.previous .step-circle,
.step-indicator.active .step-circle {
  background-color: var(--active-color);
}

.step-line {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ccc;
}

.progress-line {
  height: 100%;
  background-color: var(--active-color);
  transition: width 0.5s;
}

a.privacy-link {
  padding: 0;
}

a.back-to-home {
  text-transform: uppercase;
  text-align: center;
}

p.delete-appointment {
  margin: 1rem 0;
}

.custom-calendar {
  max-width: 100%;
  width: 800px;
  border-radius: 8px;
  background: var(--background-color);
  text-align: center;
}

/* Header with Month Navigation */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.calendar-header span {
  font-weight: bold;
  color: var(--tertiary-color);
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--tertiary-color);
  padding: 5px 10px;
  transition: 0.2s ease-in-out;
}

.calendar-header button:hover {
  color: var(--active-color);
}

/* Day Name Headers */
.calendar-days-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: bold;
  padding: 10px 0;
  background: var(--background-color);
  border-radius: 6px;
}

.day-header {
  text-align: center;
  font-size: 14px;
  color: var(--active-color);
  padding: 8px 0;
  font-weight: 600;
}

/* Calendar Grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding-top: 5px;
}

/* Day Cells */
.day {
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .day {
    height: unset;
  }
}

/* Status Colors */
.available {
  background-color: var(--success-color); /* Green */
  color: var(--tertiary-color);
}

.waitlist {
  background-color: var(--active-color); /* Orange */
  color: var(--tertiary-color);
}

.unavailable {
  background-color: var(--secondary-color); /* Gray */
  color: var(--tertiary-color);
  cursor: default;
}

/* Hover Effects */
.day.available:hover {
  background-color: var(--tertiary-color);
  color: var(--active-color);
}

.day.waitlist:hover {
  background-color: var(--tertiary-color);
  color: var(--active-color);
}
/* Empty Days for Padding */
.day.empty {
  background: transparent;
  cursor: default;
}

.date-picker::placeholder {
  color: var(--active-color) !important; /* Try a noticeable color */
  font-style: normal; /* Remove italics if needed */
  opacity: 1; /* Ensure it's fully visible */
}

.day.disabled {
  pointer-events: none; /* 🔥 Completely disables clicks */
  opacity: 0.5; /* Makes them look disabled */
  cursor: not-allowed;
}

.waitlist-time-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.waitlist-time-input label {
  color: var(--tertiary-color);
  font-weight: bold;
}

/* Shake animation */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}

.step-circle.shake {
  animation: shake 0.4s ease-in-out;
}

.login-prompt button.btn-login.btn-outline {
  width: 100%;
}

.bookings-container input {
  padding: 0.75rem !important;
  margin: 10px 0;
  border-radius: 8px;
  transition: border 0.3s ease-in-out;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: var(--secondary-color) !important;
  border: 2px solid transparent !important;
}

.bookings-container input:focus {
  border-color: var(--active-color) !important;
  outline: none;
}

.list-items.success-items .list-item .list-item-title {
  font-weight: bold;
}

@media (max-width: 992px) {
  .list-items.success-items .list-item {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .list-items.success-items .list-item .list-item-price {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .list-items.success-items .list-item .list-item-date {
    font-size: .75rem;
  }
}

.list-items.success-items .list-item-category {
  margin-left: 0;
}

.list-items.success-items .service-details {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}
.list-items.success-items .service-item {
  display: flex;
  align-items: center;
  gap: .5rem;
}
